import Swiper from 'swiper/bundle';

export const initTestimonialsSlider = () => {
    new Swiper('[data-testimonials]', {
        loop: true,
        slidesPerView: 1,

        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });
};

import { scrollTo } from '../helper-function/scrollTo';

export const initScrollToAnchor = () => {
    const links = document.querySelectorAll('[data-anchor-link]');
    if (!links.length) return;

    links.forEach((link) => {
        link.addEventListener('click', handleOnClickLink, false);
    });

    function handleOnClickLink(event) {
        event.preventDefault();
        scrollTo(this.getAttribute('href'), -150, true);
    }
};

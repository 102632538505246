import { getTarget } from '../../helper-function/get-target';

export const initMobileMenu = () => {
    // VARS
    const buttonOpen = document.querySelector('[data-open-mobile-menu]');
    const buttonClose = document.querySelector('[data-close-mobile-menu]');
    const mobileMenu = document.querySelector('[data-mobile-menu]');

    if (!buttonOpen || !buttonClose || !mobileMenu) return;

    // LISTENERS
    buttonOpen.addEventListener('click', handleOnClickOpenMenu, false);
    buttonClose.addEventListener('click', handleOnClickCloseMenu, false);

    document.addEventListener(
        'click',
        function (event) {
            if (!getTarget(event, '[data-mobile-menu-list]') && !getTarget(event, '[data-open-mobile-menu]')) {
                if (document.body.classList.contains('is-open-menu')) {
                    closeMenu();
                    enableScroll();
                }
            }
        },
        false,
    );

    // HANDLERS
    function handleOnClickOpenMenu() {
        openMenu();
        disableScroll();
    }

    function handleOnClickCloseMenu() {
        closeMenu();
        enableScroll();
    }

    // FUNCTIONS
    function openMenu() {
        document.body.classList.add('is-open-menu');
    }

    function closeMenu() {
        document.body.classList.remove('is-open-menu');
    }
};

export function InitRating() {
    // VARS
    const rating = document.querySelector('[data-rating]');
    if (!rating) return;

    const $this = this;
    const allStars = rating.querySelectorAll('[data-rating-star]');
    const ratingSubmit = document.querySelector('[data-rating-submit]');
    const activeClass = 'is-active';

    // LISTENERS AND CALLS
    allStars.forEach((star) => {
        star.addEventListener('click', handleOnClickStar, false);
    });

    ratingSubmit.addEventListener('click', handleOnClickSubmit, false);

    // HANDLERS
    function handleOnClickStar() {
        $this.deleteActiveStar();
        this.classList.add(activeClass);
        $this.toggleErrorMessage();
    }

    function handleOnClickSubmit() {
        $this.toggleErrorMessage();
    }

    // METHODS AND FUNCTIONS
    $this.deleteActiveStar = () => {
        const activeStar = rating.querySelector('.is-active[data-rating-star]');
        activeStar?.classList.remove(activeClass);
    };

    $this.isValidaRating = () => {
        const activeStar = document.querySelector('.is-active[data-rating-star]');
        return Boolean(activeStar);
    };

    $this.toggleErrorMessage = () => {
        $this.isValidaRating() ? $this.hideError() : $this.showError();
    };

    $this.showError = () => {
        const errorMessageElem = document.querySelector('[data-rating-error]');
        errorMessageElem.style.display = 'block';
    };

    $this.hideError = () => {
        const errorMessageElem = document.querySelector('[data-rating-error]');
        errorMessageElem.style.display = 'none';
    };

    $this.backDefaultLayoutRating = () => {
        $this.hideError();
        $this.deleteActiveStar();
    };
}

import HystModal from 'hystmodal';

export const initRatingModal = (initRating) => {
    const ratingSubmit = document.querySelector('[data-rating-submit]');
    if (!ratingSubmit) return;

    const modalRating = new HystModal({
        linkAttributeName: 'data-hystmodal',
        closeOnEsc: true,
        beforeOpen: function () {
            initRating.backDefaultLayoutRating();
        },
    });

    ratingSubmit.addEventListener(
        'click',
        function () {
            if (initRating.isValidaRating()) {
                modalRating.close();
                modalRating.open('[data-rating-thanks]');
            }
        },
        false,
    );
};
